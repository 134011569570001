<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <div @mousedown="(evt) => evt.stopPropagation() && evt.preventDefault()">
        <v-card flat tile elevation="0">
          <v-toolbar
            class="black--text d-flex justify-center"
            color="#fbc501"
            tile
          >
            <v-toolbar-title>Import kontaktów</v-toolbar-title>
          </v-toolbar>
          <v-card-actions class="pb-0">
            <v-col cols="12" sm="12" class="import-description">
              <p class="py-0 my-0">
                Przygotuj plik <b>.xlsx</b> lub <b>.csv</b> według poniższego
                przykładu. Możesz również pobrać przykładowy plik oraz go
                zmodyfikować.
              </p>
              <p
                class="my-1 example-file-label"
                @click="downloadExampleFile('xlsx')"
              >
                <b>Przykładowy plik (xlsx) - pobierz</b>
              </p>
              <p
                class="my-1 example-file-label"
                @click="downloadExampleFile('csv')"
              >
                <b>Przykładowy plik (csv) - pobierz</b>
              </p>
              <p class="py-0 mb-2">
                Plik musi zawierać cztery kolumny w odpowiedniej kolejności:
              </p>
              <ol>
                <li>Imię i nazwisko</li>
                <li>Numer telefonu</li>
                <li>Adres e-mail</li>
                <li>Notatka (maksymalnie 10240 znaków)</li>
              </ol>

              <p class="mt-2 import-warning">
                UWAGA! Aby uniknąć ewentualnych błędów przy importowaniu nie
                zaleca się zmian pliku źródłowego na inny format czyli np. z
                xls/xlsx na csv lub z csv na xls/xlsx.
              </p>

              <p class="mt-4">
                W przypadku problemów z importem skontaktuj się z nami.
              </p>
            </v-col>
          </v-card-actions>
          <v-card-actions class="d-flex justify-center pt-0">
            <v-col cols="12" sm="12">
              <v-file-input
                ref="fileupload"
                v-model="file"
                dense
                outlined
                rounded
                label="Dodaj plik do importu"
                accept=".xlsx,.xls,.csv,"
              ></v-file-input>
            </v-col>
          </v-card-actions>
          <BlackButton
            fab
            small
            icon="times"
            iconMargin=""
            class="close"
            @click.native="closeModal()"
          />

          <v-card-actions class="d-flex justify-center pt-5 pb-5">
            <YellowButton
              :disabled="importingInProgress"
              @click.native="startImport()"
              width="30%"
              :text="importingInProgress ? 'Importowanie...' : 'Importuj'"
            />
            <YellowButton
              :disabled="importingInProgress"
              @click.native="closeModal()"
              width="30%"
              text="Anuluj"
            />
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { URL } from "../../config";
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
import axios from "../../axios";
export default {
  name: "ImportCustomersFromFileModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlackButton,
    YellowButton,
  },
  data: () => ({
    file: null,
    importingInProgress: false,
  }),
  computed: {
    exampleXlsxContactsFile() {
      return `${URL}/import_kontaktow_przykladowy_plik.xlsx`;
    },
  },
  methods: {
    async downloadExampleFile(extension) {
      const params = { extension };
      await axios
        .get("customer/csvImportExampleFile", { params, responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `import_kontaktow_przykladowy_plik.${extension}`
          );
          document.body.appendChild(link);
          link.click();
        });
    },

    async startImport() {
      try {
        this.importingInProgress = true;
        const isImportOK = await this.$store.dispatch(
          "customers/importCustomersFromFile",
          {
            file: this.file,
          }
        );

        if (isImportOK) {
          this.$store.dispatch(
            "snackbar/setSnackbar",
            {
              color: "success",
              icon: "check",
              message: "Importowanie kontaktów zakończone pomyślnie.",
            },
            { root: true }
          );
          this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
          this.file = null;
          this.$emit("importFinished");
        } else {
          this.$store.dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Wystąpił błąd podczas importowania pliku. Sprawdź czy Twój plik jest poprawny i zawiera wszystkie wymagane kolumny. Jeżeli problem się powtarza, skontaktuj się z nami w celu uzyskania pomocy.",
            },
            { root: true }
          );
          this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wystąpił błąd podczas importowania pliku. Sprawdź czy Twój plik jest poprawny i zawiera wszystkie wymagane kolumny. Jeżeli problem się powtarza, skontaktuj się z nami w celu uzyskania pomocy.",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      } finally {
        this.importingInProgress = false;
      }
    },
    closeModal(deleted) {
      if (deleted) {
        this.$emit("closeModal", true);
        if (this.shoppingCartProduct || this.calculationProduct) {
          this.$emit("clearUpdatedProductsList");
        }
      } else {
        this.$emit("closeModal", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.import-description {
  font-size: 11px;
}
.example-file-label {
  cursor: pointer;
  color: #e2b61a;
  text-decoration: underline;
}
.outlined-form-section {
  position: relative;
  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.import-warning {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
</style>
