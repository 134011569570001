<template>
  <v-row justify="center" align="center" class="flex-column my-0">
    <v-card tile elevation="0" class="py-0 max-width-mobile">
      <v-card-title class="text-center title py-0 my-0"
        >Jak dodać aplikację do ekranu głównego?</v-card-title
      >
      <v-card-text class="text-justify my-0 pb-1 instruction">
        Aplikacja ForMYever Office jest aplikacją webową czyli aplikacją na
        każde urządzenie z dostępem do przeglądarki. Dlatego nie znajdziesz jej
        w App Store czy Google Play.
      </v-card-text>
      <v-card-text class="text-justify my-0 py-1 instruction">
        Aby używać jej jako aplikacji mobilnej na swoim telefonie musisz zapisać
        ją na pulpit Twojego telefonu prosto z przeglądarki.
      </v-card-text>
      <v-card-text class="text-justify my-0 py-1 pb-4 instruction">
        Sposób dodawania aplikacji do pulpitu różni się w zależności od systemu
        i przeglądarki z których korzystasz. Poniżej znajdują się instrukcje w
        jaki sposób wykonać instalację aplikacji na konkretną przeglądarkę.
      </v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Android </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> Chrome </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img src="../assets/android_chrome.gif"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header> Opera </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img src="../assets/android_opera.gif"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> IOS </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> Chrome </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img src="../assets/ios_chrome.gif"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header> Safari </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img src="../assets/ios_safari.gif"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-row>
</template>
<script>
export default {
  name: "AddToDesktopPage",
};
</script>
<style scoped>
.title {
  word-break: break-word;
  font-size: 16px !important;
}
.instruction {
  font-size: 12px;
}
.max-width-mobile {
  max-width: 500px;
}
@media screen and (max-width: 800px) {
  .max-width-mobile {
    width: 720px;
  }
}
@media screen and (max-width: 400px) {
  .max-width-mobile {
    width: 320px;
  }
}
</style>
