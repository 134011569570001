<template>
  <v-row justify="center" dense align-content="start">
    <v-col cols="12" sm="11" md="9" lg="7" xl="6" style="margin-bottom: 50px">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-card-actions>
          <Localisation office />
        </v-card-actions>
        <v-card-actions class="pb-1">
          <a href="tel:+48224564356" style="text-decoration: none">
            <YellowButton icon="phone-alt" xsmall text="Kontakt z Forever" />
          </a>
        </v-card-actions>
      </v-card>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <div id="name-container">
          <div id="name" ref="name" :style="{ fontSize: fontSize + 'px' }">
            {{ user.fullname }}
          </div>
        </div>

        <v-card-actions class="pt-0 pb-4"></v-card-actions>
      </v-card>

      <v-card
        style="border-radius: 5px"
        tile
        elevation="0"
        class="d-flex justify-space-around"
      >
        <v-card-text
          style="cursor: pointer; font-size: 8px"
          class="text-center fontSize px-1 noSelect tab"
          :class="{
            selectedTab: selectedTab === 'clients',
            unselectedTab: selectedTab != 'clients',
            background: selectedTab === 'clients',
          }"
          @click="
            (selectedTab = 'clients'),
              fetchCustomersOnClick(),
              goToCustomersTab()
          "
          ><span style="font-size: 11px"
            >Kontakty
            <br />
            <p style="padding: 0; margin-top: 5px; font-size: 11px">
              {{
                customers && customers.count && customers.count > 0
                  ? `(${customers.count})`
                  : `(0)`
              }}
            </p></span
          >
        </v-card-text>

        <v-card-text
          style="cursor: pointer"
          class="text-center fontSize px-1 noSelect tab"
          :class="{
            selectedTab: selectedTab === 'calendar',
            unselectedTab: selectedTab != 'calendar',
            background: selectedTab === 'calendar',
          }"
          @click="(selectedTab = 'calendar'), goToCalendarTab()"
          ><span style="font-size: 11px"
            >Kalendarz
            <br />
            <font-awesome-icon :icon="['fas', 'calendar-alt']" />
          </span>
        </v-card-text>

        <v-card-text
          style="cursor: pointer"
          class="text-center fontSize px-1 noSelect tab"
          :class="{
            selectedTab: selectedTab === 'reports',
            unselectedTab: selectedTab != 'reports',
            background: selectedTab === 'reports',
          }"
          @click="(selectedTab = 'reports'), goToReportsTab()"
          ><span style="font-size: 11px"
            >Raporty
            <br />
            <font-awesome-icon :icon="['fas', 'chart-bar']" />
          </span>
        </v-card-text>

        <v-card-text
          style="cursor: pointer"
          class="text-center fontSize px-1 noSelect tab"
          :class="{
            selectedTab: selectedTab === 'tasks',
            unselectedTab: selectedTab != 'tasks',
            background: selectedTab === 'tasks',
          }"
          @click="(selectedTab = 'tasks'), goToTasksTab()"
          ><span style="font-size: 11px"
            >Zadania
            <br />
            <font-awesome-icon :icon="['fas', 'tasks']" />
          </span>
        </v-card-text>

        <v-card-text
          style="cursor: pointer"
          class="text-center fontSize px-1 noSelect tab"
          :class="{
            selectedTab: selectedTab === 'list',
            unselectedTab: selectedTab != 'list',
            background: selectedTab === 'list',
          }"
          @click="
            (selectedTab = 'list'),
              fetchShoppingCartOnClick(),
              goToShoppingCartTab()
          "
          ><span style="font-size: 11px"
            >Zakupy
            <br />
            <font-awesome-icon :icon="['fas', 'shopping-basket']" />
          </span>
        </v-card-text>
        <v-card-text
          class="text-center fontSize px-1 noSelect tab"
          style="cursor: pointer"
          :class="{
            selectedTab: selectedTab === 'calculations',
            unselectedTab: selectedTab != 'calculations',
            background: selectedTab === 'calculations',
          }"
          @click="
            (selectedTab = 'calculations'),
              fetchCalculationsOnClick(),
              goToCalculationsTab()
          "
          ><span style="font-size: 11px"
            >Kalkulacje
            <br />
            <font-awesome-icon :icon="['fas', 'calculator']" /> </span
        ></v-card-text>
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'calculations'"
      >
        <v-card-actions class="justify-space-between mb-4 px-1">
          <div class="d-flex align-center">
            <div class="mr-1">
              <b>{{
                showDeletedCalculation
                  ? "Lista usuniętych kalkulacji"
                  : "Lista kalkulacji"
              }}</b>
            </div>

            <YellowButton
              text="Dodaj kalkulację"
              icon="plus"
              xsmall
              @click.native="moveToAddCalculation()"
              class="mr-1"
              :withoutPadding="true"
            />
          </div>

          <div class="d-flex justify-end align-center">
            <YellowButton
              text="Filtruj/Sortuj"
              icon="filter"
              xsmall
              @click.native="filterCalculations()"
              :withoutPadding="true"
            />

            <font-awesome-icon
              class="ml-1"
              icon="trash"
              :color="showDeletedCalculation ? '#fbc501' : 'black'"
              style="width: 20px; height: 20px; cursor: pointer"
              @click="handleShowDeletedCalculations()"
            />
          </div>
        </v-card-actions>
        <v-simple-table
          dense
          v-if="calculations.data && calculations.data.length > 0"
        >
          <template>
            <thead>
              <tr>
                <th class="text-left">Nr kalkulacji</th>
                <th class="text-center">Data oferty</th>
                <th class="text-center">Data zamówienia</th>
                <th class="text-left">CC</th>
                <th class="text-left">Klient</th>
                <th class="text-left"></th>
                <th class="text-left">Data Kontaktu</th>
                <th class="text-left">Status</th>
                <th class="text-left">Opłacone</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(calculation, index) in calculations.data"
                :key="calculation.id"
                :class="{ tableColor: index % 2 != 0 }"
                style="cursor: pointer"
                :style="
                  calculation.calculation_status.name === 'REALIZED_PAID' &&
                  calculation.isPaid
                    ? 'background: #DAD6D6 !important'
                    : ''
                "
              >
                <td @click="pushToCalculationPage(calculation)">
                  <span style="cursor: pointer">{{
                    calculation.identifier
                  }}</span>
                </td>
                <td
                  class="text-center date-column"
                  @click="pushToCalculationPage(calculation)"
                >
                  {{
                    calculation.offerDate
                      ? formatToYearMonthDay(calculation.offerDate)
                      : "-"
                  }}
                </td>
                <td
                  class="text-center"
                  @click="pushToCalculationPage(calculation)"
                >
                  {{
                    calculation.orderDate
                      ? formatToYearMonthDay(calculation.orderDate)
                      : "-"
                  }}
                </td>

                <td @click="pushToCalculationPage(calculation)">
                  {{ calculation.ccTotalValue }}
                </td>
                <td @click="pushToCalculationPage(calculation)">
                  <span style="cursor: pointer">
                    {{
                      calculation.customer
                        ? calculation.customer.fullname
                        : "Bez klienta"
                    }}
                  </span>
                </td>

                <td>
                  <a
                    v-if="calculation.customer && calculation.customer.phone"
                    :href="getCustomerPhone(calculation.customer)"
                  >
                    <span
                      class="fa-stack"
                      style="vertical-align: top; cursor: pointer"
                    >
                      <font-awesome-icon
                        color="#FCC502"
                        icon="circle"
                        class="fa-stack-2x"
                      />
                      <font-awesome-icon
                        icon="phone-alt"
                        color="black"
                        class="fa-stack-1x"
                      />
                    </span>
                  </a>

                  <span
                    v-else
                    @click="showPhoneMessageForCalculation(calculation)"
                    class="fa-stack"
                    style="vertical-align: top; cursor: pointer"
                  >
                    <font-awesome-icon
                      color="#f5f0e1"
                      icon="circle"
                      class="fa-stack-2x"
                    />
                    <font-awesome-icon
                      icon="phone-alt"
                      color="black"
                      class="fa-stack-1x"
                    />
                  </span>
                </td>
                <td @click="pushToCalculationPage(calculation)">
                  {{
                    calculation.customer
                      ? calcDays(calculation.customer.lastContactDate)
                      : "Brak"
                  }}
                </td>

                <td @click="pushToCalculationPage(calculation)">
                  <span
                    :class="{
                      blueText:
                        calculation.calculation_status.name === 'ORDERED',
                      yellowText:
                        calculation.calculation_status.name ===
                        'PARTIALLY_REALIZED',
                      greenText:
                        calculation.calculation_status.name === 'REALIZED_PAID',
                      redText:
                        calculation.calculation_status.name === 'NOT_REALIZED',
                    }"
                  >
                    {{ getStatusName(calculation.calculation_status.name) }}
                  </span>
                </td>

                <td>
                  <v-checkbox
                    :ref="`isPaid${calculation.id}`"
                    @change="handleIsPaidCalculation(calculation.id)"
                    color="#fbc501"
                    v-model="calculation.isPaid"
                    :value="calculation.isPaid"
                  ></v-checkbox>
                  <!-- :disabled="
                      calculation.isDeleted ||
                      (calculation.calculation_status.name ===
                        'REALIZED_PAID' &&
                        calculation.isPaid) ||
                      calculation.calculation_status.name !== 'REALIZED_PAID'
                    " -->
                </td>
                <td
                  class="text-center"
                  style="padding: 0px 5px 0px 0px; width: 10%"
                >
                  <v-menu
                    nudge-bottom="20"
                    bottom
                    left
                    v-if="!calculation.isDeleted"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        icon="ellipsis-v"
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-3"
                      />
                    </template>
                    <v-list dense>
                      <v-list-item
                        @click="editCalculation(calculation.id)"
                        v-if="!isRealizedAndPaid(calculation)"
                      >
                        <v-list-item-title>Edytuj kalkulację</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          $router.push(`/calculation-offer/${calculation.id}`)
                        "
                      >
                        <v-list-item-title>Podgląd</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <font-awesome-icon
                    v-if="calculation.isDeleted"
                    color="green"
                    class="mx-3"
                    :icon="['fas', 'trash-restore']"
                    style="width: 20px; height: 20px"
                    @click="restoreCalculation(calculation.id)"
                  />

                  <font-awesome-icon
                    color="red"
                    icon="times"
                    @click="deleteCalculation(calculation)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center" v-if="!calculations.data">
          <v-progress-circular
            indeterminate
            color="#fbc501"
          ></v-progress-circular>
        </div>
        <div
          class="text-center"
          v-if="calculations.data && calculations.data.length === 0"
        >
          {{
            showDeletedCalculation
              ? "Brak usuniętych kalkulacji"
              : "Brak kalkulacji"
          }}
        </div>
        <v-card-actions class="justify-center"
          ><YellowButton
            text="Pokaż więcej"
            small
            v-if="
              calculations.data &&
              calculations.count != calculations.data.length
            "
            @click.native="fetchCalculations()"
        /></v-card-actions>
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'list'"
      >
        <v-card-actions class="justify-space-between">
          <YellowButton
            text="Dodaj produkt"
            icon="plus"
            @click.native="addProductsModal = true"
            xsmall /><YellowButton
            text="Filtruj/Sortuj"
            icon="filter"
            xsmall
            @click.native="filterProducts()"
        /></v-card-actions>

        <v-card-text>
          <div class="sumFromShoppingCart">
            <div class="sumMoney">
              Suma zamówienia: <b>{{ moneySum }} zł</b>
            </div>
            <div class="sumCC">
              Suma CC: <b>{{ ccSum }}</b>
            </div>
          </div>
        </v-card-text>
        <v-simple-table dense v-if="products && products.length > 0">
          <template>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Nr kalkulacji</th>
                <th class="text-left">Klient</th>
                <th class="text-left">Produkt</th>
                <th class="text-left" style="width: 15%">Ilość</th>
                <th class="text-left">Cena</th>
                <th class="text-left">CC:</th>

                <th class="text-left" style="width: 15%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in products"
                :key="item.id"
                :class="{ tableColor: index % 2 != 0 }"
              >
                <td>
                  <v-checkbox
                    false-value="IN_CART"
                    true-value="IN_STOCK"
                    :disabled="item.disabled"
                    v-model="item.status"
                    @change="
                      (item.stockAmount = 0),
                        (item.amount = item.baseAmount),
                        addToUpdated(item)
                    "
                    color="#fbc501"
                  ></v-checkbox>
                </td>
                <td>
                  {{ item.calculation ? item.calculation.identifier : "-" }}
                </td>
                <td>
                  {{ item.customer ? item.customer.fullname : "-" }}
                </td>
                <td>{{ item.product ? item.product.name : "-" }}</td>
                <td>
                  <div>
                    <span
                      class="noSelect"
                      v-if="item.status === 'IN_CART'"
                      style="cursor: pointer"
                      @click="decreaseAmount(item)"
                      >-</span
                    >
                    <span
                      class="mx-5 noSelect"
                      :class="{
                        'ml-6': item.status === 'IN_STOCK' && !item.disabled,
                      }"
                      >{{ item.amount }}</span
                    >
                    <span
                      v-if="item.status === 'IN_CART'"
                      class="noSelect"
                      style="cursor: pointer"
                      @click="increaseAmount(item)"
                      >+</span
                    >
                  </div>
                  <div v-if="item.status === 'IN_STOCK' && !item.disabled">
                    <span
                      @click="decreaseStockAmount(item)"
                      class="noSelect"
                      style="cursor: pointer"
                      >-</span
                    >
                    <span class="mx-5 noSelect">{{ item.stockAmount }}</span>
                    <span
                      @click="increaseStockAmount(item)"
                      class="noSelect"
                      style="cursor: pointer"
                      >+</span
                    >
                  </div>
                </td>
                <td>
                  {{
                    item.product
                      ? roundNumber(
                          item.product.product_prices[0].value * item.amount,
                          2
                        ) + "zł"
                      : "-"
                  }}
                </td>
                <td>
                  {{
                    item.product
                      ? roundNumber(item.product.cc * item.amount, 3)
                      : "-"
                  }}
                </td>

                <td>
                  <v-btn
                    icon
                    color="red"
                    @click="deleteShoppingCartProduct(item, index)"
                  >
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center" v-if="products && products.length === 0">
          Brak produktów
        </div>
        <div class="text-center" v-if="!products">
          <v-progress-circular
            indeterminate
            color="#fbc501"
          ></v-progress-circular>
        </div>

        <v-card-actions class="justify-center"
          ><YellowButton
            text="Pokaż więcej"
            width="45%"
            v-if="
              shoppingCart.data && shoppingCart.data.length < shoppingCart.count
            "
            small
            @click.native="showMoreInShoppingCart(1000)"
          />
          <GreenButton
            text="Kup"
            width="45%"
            small
            @click.native="saveProducts()"
            v-if="
              (updatedProducts && updatedProducts.length > 0) ||
              (deletedProducts && deletedProducts.length > 0)
            "
          />
        </v-card-actions>
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'clients'"
      >
        <v-card-actions class="pb-0">
          <b class="ml-2">{{
            showDeletedCustomers
              ? "Lista usuniętych kontaktów"
              : "Lista kontaktów"
          }}</b>
        </v-card-actions>
        <v-card-actions class="justify-space-between align-center">
          <div class="d-flex align-center">
            <YellowButton
              class="mr-4"
              text="Dodaj kontakt"
              icon="plus"
              @click.native="addCustomer()"
              xsmall
            />
          </div>

          <div class="d-flex align-center">
            <YellowButton
              text="Filtruj/Sortuj"
              icon="filter"
              xsmall
              @click.native="filterCustomers()"
            />

            <font-awesome-icon
              class="ml-4"
              icon="trash"
              :color="showDeletedCustomers ? '#fbc501' : 'black'"
              style="width: 20px; height: 20px; cursor: pointer"
              @click="handleShowDeletedCustomers()"
            />
          </div>
        </v-card-actions>
        <v-card-actions class="justify-space-between align-center">
          <div class="d-flex align-center">
            <YellowButton
              class="mr-4"
              text="Import kontaktów z pliku"
              icon="file-import"
              xsmall
              @click.native="openImportCustomersFromFileModal"
            />
          </div>
        </v-card-actions>

        <v-card-actions class="d-flex align-center"
          ><v-text-field
            dense
            color="#FCC502"
            rounded
            hide-details
            v-model="filter"
            outlined
            placeholder="Wyszukaj kontakt"
            style="font-size: 16px"
            @keyup.enter="fetch()"
          ></v-text-field>
          <YellowButton
            class="ml-2"
            :disabled="filter.length < 3"
            small
            fab
            @click.native="fetch()"
            icon="search"
            onlyIcon
        /></v-card-actions>
        <v-simple-table
          dense
          v-if="customers && customers.data && customers.data.length > 0"
        >
          <template>
            <thead>
              <tr>
                <th
                  class="text-left"
                  style="margin: 0; padding: 0; padding-left: 10px"
                >
                  Imię i nazwisko
                </th>
                <th class="text-left" style="margin: 0; padding: 0">
                  <span class="table-header">Kategoria</span>
                  <br />
                  <span class="table-header">Podkategoria</span>
                </th>
                <th
                  class="text-left"
                  style="margin: 0; padding: 0; padding-right: 5px"
                >
                  Najbliższy kontakt
                </th>
                <th
                  class="text-left"
                  style="margin: 0; padding: 0; padding-right: 10px"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(customer, index) in customers.data"
                :key="customer.id"
                :class="{ tableColor: index % 2 != 0 }"
                style="cursor: pointer"
              >
                <td class="fullName-row" style="margin: 0; padding: 0">
                  <div class="name-info">
                    <customer-image-preview
                      :imagePath="customer.imagePath"
                      :phone="customer.phone"
                    />
                    <div @click="openCustomerPage(customer)">
                      <span style="cursor: pointer">{{
                        customer.fullname
                      }}</span>
                      <br />
                      <span>{{ customer.fbo }}</span>
                    </div>
                  </div>
                </td>
                <td
                  style="margin: 0; padding: 0"
                  @click="openCustomerPage(customer)"
                  v-if="
                    customer.customerCategoriesLevel1 &&
                    customer.customerCategoriesLevel1.length === 0
                  "
                >
                  -
                </td>
                <td
                  style="margin: 0; padding: 0"
                  v-else
                  @click="openCustomerPage(customer)"
                >
                  <div class="categories">
                    <span
                      v-for="(
                        category, index
                      ) in customer.customerCategoriesLevel1"
                      :key="category.id"
                    >
                      {{
                        customer.customerCategoriesLevel1.length > 1 &&
                        index + 1 != customer.customerCategoriesLevel1.length
                          ? category.name + ","
                          : category.name
                      }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="subcategories"
                      v-if="
                        customer.customerCategoriesLevel2 &&
                        customer.customerCategoriesLevel2.length > 0
                      "
                    >
                      {{
                        customer.customerCategoriesLevel2
                          .map((c) => c.name)
                          .join(", ")
                      }}
                    </span>
                  </div>
                </td>
                <td
                  style="margin: 0; padding: 0"
                  @click="openCustomerPage(customer)"
                  v-if="customer.customer_contacts.length != 0"
                >
                  <div
                    v-for="(contact, index) in customer.customer_contacts"
                    :key="contact.id"
                  >
                    <span
                      :class="[
                        index !== 0 ? 'another-contacts' : '',
                        isDateAfterNow(contact.date) ? 'color-red' : '',
                        isDateToday(contact.date) ? 'color-blue' : '',
                      ]"
                    >
                      {{ calcDays(contact.date) }}
                    </span>
                  </div>
                  <!-- <font-awesome-icon
                    v-if="
                      customer.customer_contacts.length != 0 &&
                        customer.customer_contacts[0].done
                    "
                    icon="check"
                    class="ml-2"
                    style="color:green"
                  />
                  <font-awesome-icon
                    v-if="
                      customer.customer_contacts.length != 0 &&
                        !customer.customer_contacts[0].done
                    "
                    icon="times"
                    class="ml-2"
                    style="color:red"
                  /> -->
                </td>
                <td style="margin: 0; padding: 0" v-else>-</td>
                <td style="margin: 0; padding: 0" v-if="!customer.isDeleted">
                  <v-menu nudge-bottom="20" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        icon="ellipsis-v"
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-list dense style="margin: 0; padding: 0">
                      <v-list-item
                        @click="markAsDone(customer)"
                        :disabled="customer.customer_contacts.length < 1"
                      >
                        <v-list-item-title>Odznacz spotkanie</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editCustomer(customer)">
                        <v-list-item-title>Edytuj kontakt</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openCustomerPage(customer)">
                        <v-list-item-title>Podgląd</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteCustomer(customer)">
                        <v-list-item-title>Usuń</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td style="margin: 0; padding: 0" v-else>
                  <font-awesome-icon
                    color="green"
                    class="ml-3"
                    :icon="['fas', 'trash-restore']"
                    style="width: 20px; height: 20px"
                    @click="restoreCustomer(customer.id)"
                  />

                  <font-awesome-icon
                    color="red"
                    class="ml-3"
                    icon="times"
                    @click="deleteCustomer(customer)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          class="text-center"
          v-if="customers.data && customers.data.length === 0"
        >
          {{
            showDeletedCustomers
              ? "Brak usuniętych kontaktów"
              : "Brak kontaktów"
          }}
        </div>
        <div class="text-center" v-if="!customers.data">
          <v-progress-circular
            indeterminate
            color="#fbc501"
          ></v-progress-circular>
        </div>
        <v-card-actions class="justify-center">
          <YellowButton
            :text="`Pokaż więcej (${customers.count - customers.limit})`"
            small
            v-if="
              customers.count > 5 && customers.data.length < customers.count
            "
            @click.native="showMoreCustomers()"
          />

          <YellowButton
            :text="`Pokaż wszystko`"
            small
            v-if="
              customers.count > 5 && customers.data.length < customers.count
            "
            @click.native="showAllCustomers()"
          />
        </v-card-actions>
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'calendar' || selectedTab === 'reports'"
      >
        <calendar-page v-if="selectedTab === 'calendar'" />
        <reports v-else-if="selectedTab === 'reports'" />
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'tasks'"
      >
        <user-tasks />
        <!-- <v-card-actions class="justify-space-between">
          <p class="in-progress">{{ inProgressInformation }}</p>
        </v-card-actions> -->
      </v-card>

      <FilterCustomersModal
        @fetchCustomers="fetchCustomersWithFilters"
        :isVisible="filterCustomersModal"
        @closeModal="filterCustomersModal = false"
        :startSorting="sortingCustomers"
      />
      <FilterCalculationsModal
        @fetchCalculations="fetchCalculationsWithFilters"
        :isVisible="filterCalculationsModal"
        @closeModal="filterCalculationsModal = false"
      />
      <FilterProductsModal
        @fetchProducts="fetchProductsWithFilters"
        :isVisible="filterProductsModal"
        @closeModal="filterProductsModal = false"
      />

      <CustomerModal
        :isEdit="isEditCustomer"
        :isVisible="addCustomerModal"
        @closeModal="(addCustomerModal = false), (isEditCustomer = false)"
        @fetch="fetch()"
        @newCustomerAdded="handleNewCustomerAdded"
      />
      <AddProductsModal
        :isVisible="addProductsModal"
        @closeModal="addProductsModal = false"
        @fetchShoppingCart="fetchShoppingCart()"
      />
      <ConfirmDeleteModal
        :isVisible="confirmDeleteModal"
        @closeModal="closeConfirmDeleteModal()"
        :calculation="calculationForDeletion"
        :customer="customerToDeletion"
        :shoppingCartProduct="shoppingCartProductForDelete"
        @clearUpdatedProductsList="clearUpdatedProductsList()"
      />
      <CustomerCategoriesModal
        :isVisible="categoryModal"
        @closeModal="handleCloseCustomerCategoriesModal"
      />
      <new-customer-added-modal
        :isVisible="newCustomerAddedModal"
        @closeModal="newCustomerAddedModal = false"
        @moveToContactCard="openCustomerPage"
        @planContact="handlePlanContactDateAfterNewCustomerAdded"
      />
      <set-date-time-from-to-modal
        v-if="customer && customer.id"
        :isVisible="planContactModal"
        @closeModal="closePlanContactModal()"
        @contactAdded="openCustomerPage(customer, 'calendar-section')"
        :customerId="customer.id"
        :datetimeFrom="null"
        :datetimeTo="null"
        :contact="null"
      />
      <import-customers-from-file-modal
        :isVisible="customersFromFileModal"
        @closeModal="closeCustomersFromFileModal"
        @importFinished="closeCustomersFromFileModal"
      />
    </v-col>
  </v-row>
</template>

<script>
import shared from "../shared";
import YellowButton from "../components/buttons/YellowButton";
import Localisation from "../components/Localisation";
import GreenButton from "../components/buttons/GreenButton";
import axios from "../axios";
import FilterCustomersModal from "../components/modals/FilterCustomersModal";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import FilterCalculationsModal from "../components/modals/FilterCalculationsModal";
import FilterProductsModal from "../components/modals/FilterProductsModal";
import CustomerModal from "../components/modals/CustomerModal";
import { dateCalcDays } from "../utils/dateFormatting";
import AddProductsModal from "../components/modals/AddProductsModal";
import CustomerImagePreview from "../components/CustomerImagePreview.vue";
import moment from "moment";
import UserTasks from "./UserTasks.vue";
import CalendarPage from "./CalendarPage.vue";
import Reports from "./Reports.vue";
import CustomerCategoriesModal from "../components/modals/CustomerCategoriesModal.vue";
import NewCustomerAddedModal from "../components/modals/NewCustomerAddedModal.vue";
import SetDateTimeFromToModal from "../components/modals/SetDateTimeFromToModal.vue";
import ImportCustomersFromFileModal from "../components/modals/ImportCustomersFromFileModal.vue";
export default {
  name: "MyOfficePage",
  components: {
    YellowButton,
    GreenButton,
    Localisation,
    FilterCustomersModal,
    FilterCalculationsModal,
    FilterProductsModal,
    CustomerModal,
    AddProductsModal,
    ConfirmDeleteModal,
    CustomerImagePreview,
    UserTasks,
    CalendarPage,
    Reports,
    CustomerCategoriesModal,
    NewCustomerAddedModal,
    SetDateTimeFromToModal,
    ImportCustomersFromFileModal,
  },

  data: () => ({
    inProgressInformation: "",
    tab: "",
    expand: false,
    selectedTab: "",
    customersCounter: 0,
    listCounter: 0,
    calculationsCounter: 0,
    addCustomerModal: false,
    addProductsModal: false,
    filterCustomersModal: false,
    filterCalculationsModal: false,
    filterProductsModal: false,
    confirmDeleteModal: false,
    calculationForDeletion: "",
    customerToDeletion: null,
    products: [],
    clients: [],
    updatedProducts: [],
    deletedProducts: [],
    isEditCustomer: false,
    calculationsLimit: 5,
    limit: 5,
    shoppingCartLimit: 20,
    filter: "",
    shoppingCartProductForDelete: "",
    moneySum: 0,
    ccSum: 0,
    sortingCustomers: {
      sortBy0: "contactDate",
      sortBy1: "DESC",
      category: null,
      categoryLevel2: null,
    },
    sortingCalculations: {
      sortBy0: "identifier",
      sortBy1: "DESC",
      status: null,
    },
    sortingProducts: {
      sortBy0: "name",
      sortBy1: "DESC",
      status: null,
    },
    showDeletedCalculation: false,
    showDeletedCustomers: false,
    fontSize: 34,
    categoryModal: false,
    newCustomerAddedModal: false,
    planContactModal: false,
    customersFromFileModal: false,
  }),
  watch: {
    shoppingCart() {
      let prods = JSON.parse(JSON.stringify(this.shoppingCart.data));
      this.products = prods;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].status === "IN_STOCK") {
          this.products[i].disabled = true;
          this.products[i].stockAmount = 0;
          this.products[i].baseAmount = this.products[i].amount;
        } else {
          this.products[i].disabled = false;
          this.products[i].stockAmount = 0;
          this.products[i].baseAmount = this.products[i].amount;
        }
      }
      this.calculate();
    },
    sortingCustomers() {
      this.$store.dispatch("user/setContactsSortBy", this.sortingCustomers);
    },
    filter() {
      this.$store.dispatch("user/setContactsSearchQuery", this.filter);
      if (this.filter.length === 0) {
        this.fetch();
      }
    },
    $route() {
      if (this.$route.query.tab === "2") {
        this.selectedTab = "list";
      } else if (this.$route.query.tab === "3") {
        this.selectedTab = "calculations";
      } else if (this.$route.query.tab === "1") {
        this.selectedTab = "clients";
      } else if (this.$route.query.tab === "5") {
        this.selectedTab = "tasks";
      } else if (this.$route.query.tab === "4") {
        this.selectedTab = "calendar";
      } else if (this.$route.query.tab === "6") {
        this.selectedTab = "reports";
      }
    },
  },

  mounted() {
    this.adjustFontSize();
    window.addEventListener("resize", this.adjustFontSize);
    this.$store.dispatch("customers/resetBusinessConversationsContacts", []);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustFontSize);
  },

  methods: {
    closeCustomersFromFileModal() {
      this.customersFromFileModal = false;
      this.fetch();
    },
    openImportCustomersFromFileModal() {
      this.customersFromFileModal = true;
    },
    moveToReports() {
      this.$router.push("/reports");
    },

    moveToCalendarView() {
      this.$router.push({ name: "calendar" });
    },
    handlePlanContactDateAfterNewCustomerAdded() {
      this.planContactModal = true;
    },
    closePlanContactModal() {
      this.planContactModal = false;
    },
    handleCloseCustomerCategoriesModal() {
      this.categoryModal = false;
      this.newCustomerAddedModal = true;
    },
    async handleNewCustomerAdded(id) {
      this.$store.dispatch("customers/fetchCustomer", { id });
      this.categoryModal = true;
    },
    adjustFontSize() {
      this.$nextTick(() => {
        const nameElement = this.$refs.name;
        if (!nameElement) return;
        const containerWidth = nameElement.parentElement.offsetWidth;
        let fontSize = 34; // Maksymalny początkowy rozmiar czcionki
        nameElement.style.fontSize = fontSize + "px";
        while (nameElement.scrollWidth > containerWidth && fontSize > 0) {
          fontSize--;
          nameElement.style.fontSize = fontSize + "px";
        }
        this.fontSize = fontSize;
      });
    },
    formatToYearMonthDay(date) {
      return moment(date).locale("pl").format("DD.MM.YYYY");
    },
    moveToAddCalculation() {
      this.$router.push("/calculator");
    },
    async restoreCustomer(customerId) {
      await this.$store.dispatch("customers/restoreCustomer", customerId);
      await this.fetchCustomersWithFilters();
    },
    handleShowDeletedCustomers() {
      this.showDeletedCustomers = !this.showDeletedCustomers;
      this.fetchCustomersWithFilters();
    },

    async restoreCalculation(calculationId) {
      await this.$store.dispatch(
        "calculation/restoreCalculation",
        calculationId
      );
      await this.fetchCalculations();
    },
    handleShowDeletedCalculations() {
      this.showDeletedCalculation = !this.showDeletedCalculation;
      this.fetchCalculations();
    },
    isDateAfterNow(date) {
      return moment().isAfter(moment(date));
    },
    isDateToday(date) {
      return (
        moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
      );
    },
    calculate() {
      this.sumCC();
      this.sumMoney();
    },
    isRealizedAndPaid(calculation) {
      if (
        calculation.calculation_status.name === "REALIZED_PAID" &&
        calculation.isPaid
      ) {
        return true;
      } else {
        return false;
      }
    },
    pushToCalculationPage(calculation) {
      // if (!this.isRealizedAndPaid(calculation)) {
      this.$router.push({
        name: "calculation",
        params: { calculationId: calculation.id },
      });
      // }
    },
    async handleIsPaidCalculation(calculationId) {
      await this.$store.dispatch("calculation/handleCalculationPaymentStatus", {
        calculationId,
        isPaid: !this.$refs[`isPaid${calculationId}`][0].value,
      });
      this.fetchCalculationsOnClick();
    },
    showMoreInShoppingCart(limit) {
      this.shoppingCartLimit = limit;
      this.fetchShoppingCart();
    },
    clearUpdatedProductsList() {
      this.updatedProducts = [];
      this.shoppingCartProductForDelete = null;
    },
    async deleteShoppingCartProduct(item) {
      (this.shoppingCartProductForDelete = {
        shoppingCartId: item.id,
        productId: item.product.id,
        productName: item.product.name,
        limit: this.shoppingCartLimit,
        sorting: this.sortingProducts,
      }),
        (this.confirmDeleteModal = true);
    },
    async markAsDone(item) {
      let object = {
        customerId: item.id,
        id: item.customer_contacts[0].id,
      };
      await this.$store.dispatch("customers/markContactAsDone", object);
      this.fetch();
    },

    goToCustomersTab() {
      if (this.$route.query.tab != "1") {
        this.$router.push({ query: { tab: 1 } });
      }
    },
    goToShoppingCartTab() {
      if (this.$route.query.tab != "2") {
        this.$router.push({ query: { tab: 2 } });
      }
    },
    goToTasksTab() {
      if (this.$route.query.tab != "5") {
        this.$router.push({ query: { tab: 5 } });
      }
    },
    goToCalculationsTab() {
      if (this.$route.query.tab != "3") {
        this.$router.push({ query: { tab: 3 } });
      }
    },
    goToCalendarTab() {
      if (this.$route.query.tab != "4") {
        this.$router.push({ query: { tab: 4 } });
        //this.moveToCalendarView();
      }
    },
    goToReportsTab() {
      if (this.$route.query.tab != "6") {
        this.$router.push({ query: { tab: 6 } });
      }
    },
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    editCalculation(id) {
      this.$router.push(`/calculator?id=${id}`);
    },
    getCustomerPhone(item) {
      return "tel:" + item.phone;
    },
    showPhoneMessage() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message: "Klient nie ma przypisanego numeru!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    showPhoneMessageForCalculation(calculation) {
      if (calculation.customer) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Klient nie ma przypisanego numeru!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      } else {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Kalkulacja nie ma przypisanego klienta!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    getStatusName(status) {
      return shared.formatRole(status);
    },
    async fetchCalculationsOnClick() {
      if (this.calculationsCounter > 0) {
        return;
      } else {
        let object = {
          limit: this.calculationsLimit,
          sorting: this.sortingCalculations,
          deleted: this.showDeletedCalculation,
        };
        await this.$store.dispatch("calculation/fetchCalculations", object);
        this.calculationsCounter++;
      }
    },
    async fetchCustomersOnClick() {
      if (this.customersCounter > 0) {
        return;
      } else {
        await this.$store.dispatch("customers/fetchCustomers", {
          limit: this.limit,
          sorting: this.sortingCustomers,
          filter: this.filter,
        });
        this.customersCounter++;
      }
    },
    async fetchShoppingCartOnClick() {
      if (this.listCounter > 0) {
        return;
      } else {
        await this.$store.dispatch("shoppingCart/fetchShoppingCart", {
          limit: this.shoppingCartLimit,
          sorting: this.sortingProducts,
        });
        this.listCounter++;
      }
      this.sumCC();
      this.sumMoney();
    },
    async fetchShoppingCart() {
      await this.$store.dispatch("shoppingCart/fetchShoppingCart", {
        limit: this.shoppingCartLimit,
        sorting: this.sortingProducts,
      });
      this.sumCC();
      this.sumMoney();
    },

    async saveProducts() {
      let j;
      let i;
      let temp = [];
      for (i = 0; i < this.updatedProducts.length; ++i) {
        if (this.updatedProducts[i].status === "IN_STOCK") {
          let result = this.products.filter((obj) => {
            return obj.id === this.updatedProducts[i].id;
          });
          let product = {
            productId: result[0].productId,
            amount: result[0].stockAmount,
            status: "IN_CART",
            calculationId: result[0].calculationId,
            customerId: result[0].customerId,
          };
          this.updatedProducts[i].amount =
            this.updatedProducts[i].amount - result[0].stockAmount;
          temp.push(product);
        }
      }
      for (j = 0; j < temp.length; j++) {
        this.updatedProducts.push(temp[j]);
      }

      let changedUpdatedProducts = [];
      for (let i = 0; i < this.updatedProducts.length; i++) {
        let product = {
          productId: this.updatedProducts[i].productId,
          amount: this.updatedProducts[i].amount,
          status: this.updatedProducts[i].status,
          calculationId: this.updatedProducts[i].calculationId,
          customerId: this.updatedProducts[i].customerId,
        };
        changedUpdatedProducts.push(product);
      }
      let object = {
        updated:
          changedUpdatedProducts.length > 0 ? changedUpdatedProducts : null,
        deleted: this.deletedProducts.length > 0 ? this.deletedProducts : null,
      };

      await this.$store.dispatch("shoppingCart/editShoppingCart", object);

      this.deletedProducts = [];
      this.updatedProducts = [];

      this.fetchShoppingCart();
    },
    addToUpdated(item) {
      let product = {
        id: item.id,
        productId: item.productId,
        amount: item.amount,
        status: item.status,
        calculationId: item.calculationId,
        customerId: item.customerId,
      };
      var i;
      if (this.updatedProducts.length < 1) {
        this.updatedProducts.push(product);
      } else {
        for (i = 0; i < this.updatedProducts.length; i++) {
          if (this.updatedProducts[i].id === product.id) {
            this.updatedProducts.splice(i, 1);
            var result = this.shoppingCart.data.filter((obj) => {
              return obj.id === product.id;
            });
            if (
              result[0].amount === product.amount &&
              result[0].status === product.status
            ) {
              return;
            } else {
              this.updatedProducts.push(product);
              return;
            }
          }
        }
        this.updatedProducts.push(product);
      }
    },
    deleteProduct(item, index) {
      this.products.splice(index, 1);
      this.deletedProducts.push(item.id);
      this.deleteFromUpdated(item);
    },
    deleteFromUpdated(item) {
      if (this.updatedProducts < 1) {
        return;
      } else {
        for (let i = 0; i < this.updatedProducts.length; i++) {
          if (this.updatedProducts[i].productId === item.productId) {
            this.updatedProducts.splice(i, 1);
          }
        }
      }
    },
    calcDays(date) {
      return dateCalcDays(date);
    },

    increaseAmount(item) {
      item.amount++;
      item.baseAmount = item.amount;
      this.addToUpdated(item);
      this.calculate();
    },
    increaseStockAmount(item) {
      if (item.stockAmount < item.baseAmount - 1) {
        this.tempAmount = item.amount;
        item.amount -= 1;
        item.stockAmount += 1;
      } else {
        return;
      }
    },
    decreaseAmount(item) {
      if (item.amount > 1) {
        item.amount--;
        item.baseAmount = item.amount;
        this.addToUpdated(item);
        this.calculate();
      } else {
        return;
      }
    },
    decreaseStockAmount(item) {
      if (item.stockAmount > 0) {
        item.amount++;
        item.stockAmount -= 1;
      } else {
        return;
      }
    },
    async addCustomer() {
      await this.$store.dispatch("customers/setCustomerToNull");
      this.isEditCustomer = false;
      this.addCustomerModal = true;
    },
    showMoreCustomers() {
      this.limit += 5;
      this.$store.dispatch("user/setContactsPagination", { limit: this.limit });
      this.fetch();
    },
    showAllCustomers() {
      this.limit = 10000;
      this.$store.dispatch("user/setContactsPagination", { limit: this.limit });
      this.fetch();
    },
    async fetchCustomersWithFilters(sortingData) {
      this.sortingCustomers = sortingData;
      this.fetch();
    },
    async fetchCalculationsWithFilters(sortingData) {
      this.sortingCalculations = sortingData;
      await this.$store.dispatch("calculation/fetchCalculations", {
        limit: this.calculationsLimit,
        sorting: this.sortingCalculations,
        deleted: this.showDeletedCalculation,
      });
    },
    async fetchProductsWithFilters(sortingData) {
      this.sortingProducts = sortingData;
      await this.fetchShoppingCart();
    },
    async fetch() {
      await this.$store.dispatch("customers/fetchCustomers", {
        filter: this.filter,
        limit: this.limit,
        sorting: this.sortingCustomers,
        deleted: this.showDeletedCustomers,
      });
    },
    async fetchCalculations() {
      this.calculationsLimit += 5;
      let object = {
        limit: this.calculationsLimit,
        sorting: this.sortingCalculations,
        deleted: this.showDeletedCalculation,
      };
      await this.$store.dispatch("calculation/fetchCalculations", object);
    },

    openCustomerPage(item, scrollToItemId) {
      if (item?.id || this.customer)
        this.$router.push({
          name: "customer",
          params: { customerId: item?.id || this.customer.id },
          query: scrollToItemId ? { to: scrollToItemId } : {},
        });
    },
    filterCustomers() {
      this.$store.dispatch("customers/fetchCustomerCategories");
      this.filterCustomersModal = true;
    },
    async filterCalculations() {
      await this.$store.dispatch("calculation/fetchCalculationStatuses");
      this.filterCalculationsModal = true;
    },
    filterProducts() {
      this.filterProductsModal = true;
    },
    async deleteCalculation(item) {
      this.calculationForDeletion = item;
      this.confirmDeleteModal = true;
    },
    async deleteCustomer(customer) {
      this.customerToDeletion = customer;
      this.confirmDeleteModal = true;
    },
    async editCustomer(item) {
      let object = {
        id: item.id,
      };
      await this.$store.dispatch("customers/fetchCustomer", object);
      this.isEditCustomer = true;
      this.addCustomerModal = true;
      setTimeout(() => {
        document.getElementById("customer-modal-title").scrollIntoView();
      }, 100);
    },
    closeConfirmDeleteModal() {
      if (this.calculationForDeletion) {
        this.fetchCalculationsWithFilters();
      }
      if (this.customerToDeletion) {
        this.fetch();
      }
      this.customerToDeletion = "";
      this.calculationForDeletion = "";
      this.confirmDeleteModal = false;
    },
    sumMoney() {
      let sum = 0;
      const productsWithStatusInCart = this.products.filter(
        (element) => element.status === "IN_CART"
      );
      productsWithStatusInCart.forEach((element) => {
        sum =
          sum +
          parseFloat(element.product.product_prices[0].value) * element.amount;
      });
      this.moneySum = parseFloat(sum).toFixed(2);
    },
    sumCC() {
      let sum = 0;
      const productsWithStatusInCart = this.products.filter(
        (element) => element.status === "IN_CART"
      );

      productsWithStatusInCart.forEach((element) => {
        sum = sum + parseFloat(element.product.cc) * parseFloat(element.amount);
      });
      this.ccSum = parseFloat(sum).toFixed(3);
    },
  },

  async created() {
    if (this.contactsSearchQuery) {
      this.filter = this.contactsSearchQuery;
    }
    if (this.contactsSortBy) {
      this.sortingCustomers = this.contactsSortBy;
    }
    if (this.contactsPagination) {
      this.limit = this.contactsPagination.limit;
    }
    const inProgressResponse = await axios.get(`custom-text/inProgress`);
    this.inProgressInformation = inProgressResponse.data;
    this.$store.dispatch("user/fetchUser");
    var route = window.localStorage.getItem("lastRoute");
    var query = this.$route.query.tab ? this.$route.query.tab : "";
    if (route === "shoppingList" || query === "2") {
      this.selectedTab = "list";
      this.fetchShoppingCartOnClick();
    } else if (
      route === "calculation" ||
      route === "calculator" ||
      query === "3"
    ) {
      this.selectedTab = "calculations";
      this.fetchCalculationsOnClick();
    } else if (route === "clients" || query === "1") {
      this.selectedTab = "clients";
      this.fetchCustomersOnClick();
    } else if (route === "tasks" || query === "5") {
      this.selectedTab = "tasks";
    } else if (route === "calendar" || query === "4") {
      this.selectedTab = "calendar";
    } else if (route === "reports" || query === "6") {
      this.selectedTab = "reports";
    } else {
      this.selectedTab = "clients";
      this.fetchCustomersOnClick();
    }

    this.sortingProducts = {
      sortBy0: "createdAt",
      sortBy1: "DESC",
      status: "",
    };
    this.$store.dispatch("customers/fetchCustomerCategories");
  },

  computed: {
    customer() {
      return this.$store.getters["customers/getCustomer"];
    },
    calculations() {
      return this.$store.getters["calculation/getCalculations"];
    },
    customers() {
      const customers = this.$store.getters["customers/getCustomers"];
      if (customers && customers.data && customers.data.length > 0) {
        customers.data.forEach((customer) => {
          const customerCategories = customer.customer_categories.sort(
            (a, b) =>
              new Date(a.customers_categories.createdAt) -
              new Date(b.customers_categories.createdAt)
          );
          const customerCategoriesLevel1 = customerCategories.filter(
            (c) => !c.level || c.level === 1
          );

          const customerCategoriesLevel2 = customerCategories.filter(
            (c) => c.level === 2
          );

          customer.customerCategoriesLevel1 = customerCategoriesLevel1;
          customer.customerCategoriesLevel2 = customerCategoriesLevel2;
        });
        return customers;
      } else {
        return customers;
      }
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    shoppingCart() {
      let prods = this.$store.getters["shoppingCart/getShoppingCart"];
      return prods;
    },
    contactsSearchQuery() {
      return this.$store.getters["user/getContactsSearchQuery"];
    },
    contactsSortBy() {
      return this.$store.getters["user/getContactsSortBy"];
    },
    contactsPagination() {
      return this.$store.getters["user/getContactsPagination"];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 13px;
}

th,
td {
  padding: 5px !important;
}
th {
  vertical-align: bottom;
}

.tab {
  border-left: none;
  display: flex;
  justify-content: center;
  padding: 5px 0 0 0;
}
.tab svg {
  font-size: 22px;
  margin-top: 5px;
}
.tab:nth-child(1) {
  border-left: 1px solid grey;
}
.in-progress {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 3rem;
}
.categories {
}
.subcategories,
.another-contacts {
  font-size: 11px !important;
}
.color-red {
  color: red;
}
.color-blue {
  color: rgb(15, 15, 153);
}
.fullName-row {
  padding: 5px !important;
  padding-right: 0;
}
.selectedTab {
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: bold;
}
.unselectedTab {
  border-bottom: 1px solid grey;
  // border-radius: 0px;
  border-radius: 10px 10px 0px 0px !important;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
}
.selectedTabItem {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-radius: 0px 0px 10px 10px !important;
}
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.name-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.8rem;
  }
}
text {
  color: black !important;
}
.background {
  background-color: #fbc501;
  color: black !important;
}
// .strikethrough {
//   position: relative;
// }

// .strikethrough:before {
//   border-bottom: 8px solid rgba(251, 197, 1, 0.4);
//   position: absolute;
//   content: "";
//   width: 100%;
//   height: 126%;
// }
.break {
  word-break: break-word;
}
.sumFromShoppingCart {
  color: black;
  display: flex;
  justify-content: center;
  font-size: 16px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  padding: 15px;
  max-width: 70%;
  margin: auto;
  margin-bottom: 20px;
  background: #f0f0f0;
}
.sumMoney,
.sumCC {
  margin: 0 40px 0 40px;
}
#name-container {
  width: 100%;
  max-width: 1280px;
  padding: 10px 0;
  overflow: hidden; /* Zapobiega przewijaniu, jeśli tekst jest zbyt długi */
}
#name {
  white-space: nowrap; /* Utrzymuje tekst w jednej linii */
}
.table-header {
  font-size: 12px;
}

@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .sumFromShoppingCart {
    width: 100%;
    font-size: 14px !important;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 85%;
  }
  .sumMoney {
    margin-left: 0;
  }
  .sumCC {
    margin-right: 0;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .sumFromShoppingCart {
    width: 100%;
    font-size: 14px !important;
    flex-direction: column;
    text-align: center;
  }
  .sumMoney,
  .sumCC {
    margin: 0;
  }
}
</style>
