<template>
  <v-row justify="center" v-if="!loading">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      overlay-opacity="1"
      transition="slide-y-transition"
      height="auto"
    >
      <div @mousedown="(evt) => evt.stopPropagation() && evt.preventDefault()">
        <v-card flat tile elevation="0">
          <v-toolbar
            class="black--text d-flex justify-center"
            color="#fbc501"
            tile
          >
            <v-toolbar-title>Dodaj aplikację do pulpitu</v-toolbar-title>
          </v-toolbar>
          <div v-if="this.deferredPrompt" class="text-center">
            <p class="my-4">
              <b>Możesz zainstalować aplikację na swoim urządzeniu</b>
            </p>
            <v-card-actions class="d-flex justify-center pt-5 pb-5">
              <YellowButton
                @click.native="install()"
                width="40%"
                text="Zainstaluj"
              />
              <YellowButton
                @click.native="closeModal()"
                width="40%"
                text="Zamknij"
              />
            </v-card-actions>
          </div>
          <div v-else>
            <v-card-actions class="bold pt-5 d-flex justify-center">
              <add-to-desktop-page />
            </v-card-actions>
            <v-card-actions class="d-flex justify-center pt-3 pb-5">
              <YellowButton
                @click.native="closeModal()"
                width="40%"
                text="Zamknij"
              />
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import AddToDesktopPage from "../../pages/AddToDesktopPage.vue";
// import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
export default {
  name: "AddToDesktopModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    deferredPrompt: {
      type: Object,
      default: null,
    },
  },
  components: {
    // BlackButton,
    YellowButton,
    AddToDesktopPage,
  },
  mounted() {},
  data: () => ({
    deferredPrompt: null,
    loading: false,
  }),
  computed: {
    isAppInstalled() {
      return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone
      );
    },
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
      const choiceResult = await this.deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
