var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.navigation.includes(_vm.$route.name) && !_vm.getIsLoading)?_c('NavigationDrawer',{staticClass:"navigation-drawer"}):_vm._e(),_c('Snackbar'),_c('v-main',{class:{
      background: _vm.background.includes(_vm.$route.name),
    },staticStyle:{"min-height":"100vh"},nativeOn:{"click":function($event){return _vm.onClick()}}},[_c('v-container',{class:{
        'px-0': _vm.container.includes(_vm.$route.name),
        'py-0': _vm.getIsLoading,
        'pt-0': _vm.noPaddingTop.includes(_vm.$route.name),
        'container-padding': _vm.containerPadding.includes(_vm.$route.name),
      },attrs:{"fluid":"","fill-height":_vm.fillHeight.includes(_vm.$route.name)}},[(!_vm.arrow.includes(_vm.$route.name))?_c('v-btn',{staticClass:"shadow",class:{ arrowMargin: _vm.$vuetify.breakpoint.mdAndUp },staticStyle:{"background-image":"linear-gradient(","z-index":"10000000"},attrs:{"fab":"","width":"48px","height":"48px","color":"#fbc501","fixed":"","top":_vm.$vuetify.breakpoint.mdAndUp,"left":_vm.$vuetify.breakpoint.mdAndUp,"bottom":_vm.$vuetify.breakpoint.name === 'xs' ||
          _vm.$vuetify.breakpoint.name === 'sm',"right":_vm.$vuetify.breakpoint.name === 'xs' ||
          _vm.$vuetify.breakpoint.name === 'sm'},on:{"click":_vm.moveToHomePageOrOnePageBack}},[_c('font-awesome-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"arrow-left","size":"2x"}})],1):_vm._e(),(_vm.getIsLoading)?_c('div',{staticClass:"text-center d-flex justify-center align-center",staticStyle:{"background-color":"#fbc501","width":"100vw","height":"100vh"}},[_c('v-progress-circular',{attrs:{"size":100,"indeterminate":"","color":"black"}})],1):_c('router-view')],1),_c('add-to-desktop-modal',{attrs:{"isVisible":_vm.addToDesktopModalVisible,"deferredPrompt":_vm.deferredPrompt},on:{"closeModal":function($event){_vm.addToDesktopModalVisible = false}}})],1),(!_vm.footer.includes(_vm.$route.name))?_c('Footer',{staticStyle:{"width":"100%"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }